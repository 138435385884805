import { Box, Button, Grid, MenuItem } from '@mui/material';
import { TIPOS_PLANTILLA_IMPORTACION_ENVIOS } from 'app/helpers/constants';
import { useFormikContext } from 'formik';
import { FormSelect, FormTextInput } from '../../../../../../componentes/formularios/core';
import { pintaOpcionesSelect } from '../../../../../../helpers/libFunciones';
import { usePlantillasContext } from '../../../../../../hooks/usePlantillas';
import CamposPlantilla from './CamposPlantilla/CamposPlantilla';


const FormularioPlantilla = () => {

    const {redes, tiposPlantillas, permisoGuardar} = usePlantillasContext();

    const {values} = useFormikContext();

    let opcionesTipoImportacionEnvios = Object.keys(TIPOS_PLANTILLA_IMPORTACION_ENVIOS).map((key) => {

        return {
            id: TIPOS_PLANTILLA_IMPORTACION_ENVIOS[key].id, 
            nombre: TIPOS_PLANTILLA_IMPORTACION_ENVIOS[key].nombre
        }
    })

    return ( 
        <>
            <Box mb={1}>
                <Grid container spacing={1}>
                    <Grid item lg={2} xs={12}>
                        <FormTextInput fast name="nombre" label="Nombre" />
                    </Grid>
                    <Grid item lg={2} xs={12}>
                        <FormSelect label="Tipo" name="idTipoPlantilla">
                            {pintaOpcionesSelect(tiposPlantillas, "id", "nombre", "selectTiposPlantilla")}
                        </FormSelect>
                    </Grid>
                    {
                        values.idTipoPlantilla === "1" && (
                            <>
                                <Grid item lg={2} xs={12}>
                                    <FormSelect label="Tipo de P.envíos" name="tipoImportacionEnvios">
                                        {pintaOpcionesSelect(opcionesTipoImportacionEnvios, "id", "nombre", "selectTipoImportacionEnvios")}
                                    </FormSelect>
                                </Grid>
                                <Grid item lg={2} xs={12}>
                                    <FormSelect label="Red" name="idRed">
                                        {pintaOpcionesSelect(redes, "id", "nombre", "selectRedesPlantilla")}
                                    </FormSelect>
                                </Grid>
                            </>
                        )
                    }
                    
                    <Grid item lg={2} xs={12}>
                        <FormTextInput type="number" fast name="inicioDatos" tooltip="Nº de fila en la que empiezan a haber datos" label="Inicio datos" />
                    </Grid>
                    <Grid item lg={2} xs={12}>
                        <FormSelect label="Formato" name="formato">
                            <MenuItem value="excel">Excel</MenuItem>
                        </FormSelect>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <FormTextInput fast name="descripcion" multiline rows={4} label="Descripción" InputLabelProps={{shrink:true}} />
                    </Grid>

                </Grid>
            </Box>
            <Box mb={1}>
                <CamposPlantilla />
            </Box>
            <Box mb={1} display="flex" justifyContent="flex-end">
                <Button disabled={!permisoGuardar} type="submit"  variant="contained" color="primary">
                    Guardar
                </Button>
            </Box>

        </>
     );
}
 
export default FormularioPlantilla;