
export const redes = {
    PROPIA: 1,
    CORREOS: 2,
    CORREOS_EXPRESS:3,
    GLS:4,    
    TIPSA:5,
    SEUR:6,
    MRW:7,
    NACEX:8,
    UPS:9,
    DHL:10,
    ONTIME:11,
    CTT:12,
    DYNAMIC_EXPRESS: 13,
    CAINIAO: 14
}

export const PAIS_DEFECTO = "214";

export const IVA = 21;

export const IVA_REF = "IVA-21";

export const mimeTypes = [
    { id: "", value: "Todos" }, 
    { id: "text/plain", value: "Documentos texto plano (.txt,.html,...)" }, 
    { id: "image/*", value: "Imágenes" }, 
    { id: "application/pdf", value: "PDF" }, 
    { id: "application/msword,application/word,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msexcel,application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/mspowerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.presentation", value: "Documentos (Word,Excel,PDF,...)" }
];

export const PLANTILLAS_IMPORTACION = {
    ENVIOS: 1,
    CLIENTES: 2,
    DIRECCIONES: 3,
    REEMBOLSOS: 4,
    DEPARTAMENTOS: 5,
}

export const TIPOS_CATEGORIAS = {
    CLIENTES: 1,
    ENVIOS: 2,
    RECOGIDAS: 9,
    TARIFAS: 11,
    TODOS: 13
}

export const IMPORTE_FIJO_DEBIDOS = 5;

export const TIPOS_REFERENCIAS = {
    BULTO: 1,
    KILO: 2,
    DIRECCION: 3,
    VARIABLE: 4
}

export const MODELOS_FACTURAS = {
    COMPLETO: {
        id: "completo",
        nombre: "Completo"
    },
    RESUMIDO: {
        id: "resumido",
        nombre: "Resumido"
    },
    AGRUPADO_RESUMIDO: {
        id: "agrupado_resumido",
        nombre: "Agrupado resumido"
    },
    AGRUPADO_COMPLETO: {
        id: "agrupado_completo",
        nombre: "Agrupado completo"
    },
}

export const TIPOS_NOTIFICACIONES_ENVIOS = {
    ENVIO_TRANSITO: {
        id: "1",
        nombre: 'Envío en tránsito'
    }
}

export const VARIABLES_ACCIONES_RED = [
    { id: "acuse", nombre: "Acuse" }, 
    { id: "entrega_sabado", nombre: "Entrega Sabado" }, 
    { id: "retorno", nombre: "Retorno" }, 
    { id: "retorno_opcional", nombre: "Retorno opcional" }, 
    { id: "retorno_albaran", nombre: "Retorno albarán" }, 
    { id: "prueba_entrega", nombre: "Prueba entrega" }, 
    { id: "entrega_830", nombre: "Entrega 8:30" }, 
    { id: "envio_sms", nombre: "Envio SMS" }, 
    { id: "dninombre", nombre: "DNI+Nombre" },
    { id: "entrega_gran_superficie", nombre: "Entrega en gran superficie" },
    { id: "documentar_expedicion", nombre: "Documentar expedición" }
];

export const TIPOS_FICHEROS_LECTURA = {
    GENERAL: 'general',
    AMAZON: 'amazon'
}

export const TIPOS_PLANTILLA_IMPORTACION_ENVIOS = {
    CREAR: {
        id: "crear",
        nombre: "Envíos nuevos"
    },
    ACTUALIZAR: {
        id: "actualizar",
        nombre: "Costes/Recogida/Interciudades"
    },
    RECOGIDA_PLAZA: {
        id: "crear_recogida_plaza",
        nombre: "Recogida fuera de plaza"
    }
}


export const TIPOS_METODOS_PAGO = {
    TRANSFERENCIA: {
        id: "1",
        nombre: "Transferencia"
    },
    REDSYS: {
        id: "2",
        nombre: "Redsys"
    },
} as const;
