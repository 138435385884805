import { Grid } from '@mui/material';
import { Alert } from "@mui/material";
import React from 'react';
import SpinnerCargando from '../../../../componentes/generales/SpinnerCargando';
import { usePlantillas } from '../../../../hooks/usePlantillas';
import { Portlet, PortletBody, PortletHeader } from '../../../../partials/content/Portlet';
import DatosPlantilla from './DatosPlantilla/DatosPlantilla';

const FichaPlantilla = ({id}) => {

    const {cargando, error: {error, msg}} = usePlantillas(id);


    return (
        <>
          <Portlet>
                <PortletHeader 
                title={"Plantilla"}
                goBack={"/plantillas"}
                />
                <PortletBody>
                    {
                        cargando ? (
                            <SpinnerCargando posicion="center"/>
                        )
                        :
                        (
                          <>
                              {
                                !error ? (
                                      <>
                                          <DatosPlantilla />
                                      </>
                                )
                                :
                                (
                                    <Grid item lg={12} xs={12}>
                                        <Alert severity="error">
                                            {msg}
                                        </Alert>

                                    </Grid>
                                )
                              }
                              
                          </>
                            
                        )
                    }
                </PortletBody>

          </Portlet>

        </>
      );
}
 
export default FichaPlantilla;