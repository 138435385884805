import { Formik } from 'formik';
import React from 'react';
import { usePlantillasContext } from '../../../../../hooks/usePlantillas';
import FormularioPlantilla from './FormularioPlantilla/FormularioPlantilla';

const DatosPlantilla = () => {

    const {schemaPlantillas, datosPlantilla, guardarPlantilla} = usePlantillasContext();


    return ( 
        <>
            <Formik enableReinitialize validateOnChange={true} validateOnBlur={false} validationSchema={schemaPlantillas} initialValues={datosPlantilla} onSubmit={guardarPlantilla}>
            {
                formik => (
                    <form onSubmit={formik.handleSubmit}>
                        <FormularioPlantilla />
                    </form>
                )
            }
            </Formik>
        </>
     );
}
 
export default DatosPlantilla;