import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import RoutePermisos from "../../componentes/acceso/RoutePermisos";
import { useRoles } from "../../hooks/hooksPropios";
import { FichaEnvioProvider } from "../../hooks/useEnvios";
import { FichaEntregaMensajeroProvider } from "../../hooks/useFichaEntregaMensajero";
import { FichaRecogidaMensajeroProvider } from "../../hooks/useFichaRecogidaMensajero";
import { PlantillasProvider } from "../../hooks/usePlantillas";
import { RecogidaProvider } from "../../hooks/useRecogida";
import Multilogin from './../../componentes/acceso/Multilogin';
import Ayuda from "./Ayuda/Ayuda";
import Builder from "./Builder";
import ConfigPage from "./config/ConfigPage";
import ConfiguracionPage from "./ConfiguracionPage";
import Dashboard from "./Dashboard";
import FichaPlantilla from "./Plantillas/FichaPlantilla/FichaPlantilla";
import FichaSeguimiento from "./seguimiento/FichaSeguimiento/FichaSeguimiento";
import Soporte from "./Soporte";
import { ListadoTraficoProvider } from "./trafico/hooks/useTrafico";





const Redes = lazy(()=> import('./redes'));
const FichaRedes = lazy(() => import('./redes/FichaRedes'));
const Tarifas = lazy(() => import('./tarifas'));
const FichaTarifas = lazy(() => import('./tarifas/FichaTarifas'));
const Simulador = lazy(() => import('./simulador'));
const Utilidades = lazy(() => import('./utilidades'));
const Transmision = lazy(() => import('./transmision'));
const Importacion = lazy(() => import('./importacion'));
const FichaRecogidaMensajero = lazy(() => import('./mensajero/recogidas/FichaRecogidaMensajero/FichaRecogidaMensajero'));
const FichaEntregaMensajero = lazy(() => import('./mensajero/envios/FichaEntregaMensajero/FichaEntregaMensajero'));
const FichaTrafico = lazy(() => import('./trafico/FichaTrafico'));
const FichaRecogida = lazy(() => import("./recogidas/FichaRecogida"));
const FichaEnvios = lazy(() => import("./envios/FichaEnvios"));
const Clientes = lazy(() => import('./clientes'));
const FichaCliente = lazy(() => import('./clientes/FichaCliente'));
const FichaFacturar = lazy(() => import('../facturacion/FacturarPage'));
const ListadoPlantillas = lazy(() => import('./Plantillas/ListadoPlantillas/ListadoPlantillas'));



export default function HomePage() {

  const {MENSAJERO, INTERNO, ADMIN, MULTILOGIN, CLIENTE, SUBAGENCIA} = useRoles();

 

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen visible={true} />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <Route path="/builder" component={Builder} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/soporte" component={Soporte} />
          <Route path="/ayuda" component={Ayuda} />

          <Route path="/proyecto/:proyecto/:destino" exact render={(props)=> <Multilogin proyecto={props.match.params.proyecto} destino={props.match.params.destino}></Multilogin> } />
          <Route path="/proyecto/:proyecto/" exact render={(props) => <Multilogin proyecto={props.match.params.proyecto}></Multilogin>} />
          
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN]} path="/config" component={ConfigPage} />

          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN]} path="/configuracion" component={ConfiguracionPage} />

          {/* Tarifas */}
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, SUBAGENCIA]} path="/tarifas/:id/:tab" exact render={(props) => <FichaTarifas key={props.match.params.id} id={props.match.params.id} tabInicio={props.match.params.tab} />} />
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, SUBAGENCIA]} path="/tarifas/:id" render={(props) => <FichaTarifas key={props.match.params.id} id={props.match.params.id} />} />
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, SUBAGENCIA]} path="/tarifas" component={Tarifas} />
        
          
          {/* Redes */}
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN]} path="/redes/:id/:tab" exact render={(props) => <FichaRedes key={props.match.params.id} id={props.match.params.id} tabInicio={props.match.params.tab} />} />        
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN]} path="/redes/:id" render={(props) => <FichaRedes key={props.match.params.id} id={props.match.params.id} />} />
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN]} path="/redes" component={Redes} />        

          {/* Clientes */}
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, CLIENTE]} path="/clientes/:id/:tab" render={(props) => <FichaCliente key={props.match.params.id} id={props.match.params.id} tabInicio={props.match.params.tab} />} />  
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, CLIENTE]} path="/clientes/:id" render={(props) => <FichaCliente key={props.match.params.id} id={props.match.params.id} />} />
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN]} path="/clientes" component={Clientes} />        

          {/*Recogidas y envios*/}
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, CLIENTE, MENSAJERO, SUBAGENCIA]} path="/recogidas/:id/:tab" render={(props) => <RecogidaProvider><FichaRecogidaMensajeroProvider><FichaRecogida key={props.match.params.id} id={props.match.params.id} tabInicio={props.match.params.tab} /></FichaRecogidaMensajeroProvider></RecogidaProvider>}/>
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, CLIENTE, MENSAJERO, SUBAGENCIA]} path="/recogidas/:id" render={(props) => <RecogidaProvider><FichaRecogidaMensajeroProvider><FichaRecogida key={props.match.params.id} id={props.match.params.id} /></FichaRecogidaMensajeroProvider></RecogidaProvider>}/>
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, CLIENTE, MENSAJERO, SUBAGENCIA]} path="/envios/:id/:tab" render={(props) => <FichaEnvioProvider><FichaEnvios key={props.match.params.id} id={props.match.params.id} tabInicio={props.match.params.tab}/></FichaEnvioProvider>}/>
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, CLIENTE, MENSAJERO, SUBAGENCIA]} path="/envios/:id" render={(props) => <FichaEnvioProvider key={props.location.key}><FichaEnvios key={props.match.params.id} id={props.match.params.id}/></FichaEnvioProvider>}/>
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, SUBAGENCIA]} path="/trafico" render={() => <ListadoTraficoProvider><FichaTrafico titulo="Tráfico"/></ListadoTraficoProvider>}/>
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, CLIENTE, MENSAJERO, SUBAGENCIA]} path="/seguimiento/:id" render={(props) => <FichaSeguimiento key={props.match.params.id} id={props.match.params.id} />}/>

          
          {/* Simulador */}
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, CLIENTE, SUBAGENCIA]} path="/presupuestos" component={Simulador} />        

          {/* Utilidades */}
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN]} path="/utilidades" component={Utilidades} />              

          {/* Transmision */}
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN]} path="/transmision" component={Transmision} />  

          {/* Importación */}
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, SUBAGENCIA]} path="/importacion" component={Importacion} /> 


          {/* Plantillas */}
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN, CLIENTE]} path="/plantillas/:id" render={(props) => <PlantillasProvider><FichaPlantilla key={props.match.params.id} id={props.match.params.id} /></PlantillasProvider>}/>
          <RoutePermisos roles={[INTERNO, MULTILOGIN, ADMIN]} path="/plantillas" component={ListadoPlantillas} />
          {/* Mensajero */}

          <RoutePermisos roles={[MENSAJERO]} path="/mensajero/recogidas/:id" render={(props) => <FichaRecogidaMensajeroProvider><RecogidaProvider><FichaRecogidaMensajero key={props.match.params.id} id={props.match.params.id}/></RecogidaProvider></FichaRecogidaMensajeroProvider>}/> 
          <RoutePermisos roles={[MENSAJERO]} path="/mensajero/entregas/:id" render={(props) => <FichaEntregaMensajeroProvider><FichaEntregaMensajero key={props.match.params.id} id={props.match.params.id}/></FichaEntregaMensajeroProvider>}/> 
          <RoutePermisos roles={[MENSAJERO]} path="/mensajero/trafico" render={() => <ListadoTraficoProvider><FichaTrafico titulo={"Tráfico"} /></ListadoTraficoProvider>}/>
        

          {/* RUTAS CLIENTE */}
          <RoutePermisos roles={[CLIENTE]} path="/cliente/trafico" render={() => <ListadoTraficoProvider><FichaTrafico titulo={"Tráfico"} /></ListadoTraficoProvider>}/>
          

          {/* FACTURACION */}
          <RoutePermisos roles={[INTERNO, ADMIN, MULTILOGIN]} path="/facturacion/facturar" render={() => <FichaFacturar />}/>
         
          <Redirect to="/error/error-v1" />
          

        </Switch>
      </Suspense>
    </>
  );
}
