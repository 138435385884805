import { cloneDeep, isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";


export interface DatatableFiltersState {
    filtros: any;
    setFiltrosDatatable: (values: any) => void;
    resetFiltros: (reset: UseFormReset<FieldValues>) => void;
}


export function useDatatableFilters(defaultFilterValues: any = {}, goToFirstPage : () => void) : DatatableFiltersState {

    const [filtros, setFiltros] = useState(defaultFilterValues);

    const setFiltrosDatatable = useCallback((values: any) => {
        let filtrosSetear = cloneDeep(values);

        Object.keys(filtrosSetear).forEach((key) => {
            if (filtrosSetear[key] === "" || filtrosSetear[key] === null || filtrosSetear[key] === undefined || (typeof filtrosSetear[key] !== "number" && isEmpty(filtrosSetear[key])))
            {
                delete filtrosSetear[key];
            }
        });

        goToFirstPage();

        setFiltros(filtrosSetear);

    }, [goToFirstPage]);

    const resetFiltros = useCallback((reset: UseFormReset<FieldValues>) => {

        goToFirstPage();
        setFiltros({});
        reset();
    
    }, [goToFirstPage]);

    return {
        resetFiltros,
        setFiltrosDatatable,
        filtros
    }

}