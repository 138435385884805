import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, MenuItem } from '@mui/material';
import BotonCircular from 'app/componentes/generales/BotonCircular';
import { useFormikContext } from 'formik';
import { FormSelect, FormTextInput } from '../../../../../../../../componentes/formularios/core';

const CampoTipoPlantilla = ({campo}) => {

    const {values, setFieldValue} = useFormikContext();


    const addCampoExtra = (tipo) => {

        
        const objeto = {
            tipo: tipo,
            valor: ''
        }

        setFieldValue(`campos[${campo.idTipoCampo}].jsonColumnas`, [...values.campos[campo.idTipoCampo].jsonColumnas, objeto]);

    }

    const removeCampoExtra = (indiceCampoExtra) => {


        var camposNuevos = [...values.campos[campo.idTipoCampo].jsonColumnas];
        
        camposNuevos.splice(indiceCampoExtra, 1);

        setFieldValue(`campos[${campo.idTipoCampo}].jsonColumnas`, camposNuevos);


    }


    if (values.campos[campo.idTipoCampo])
    {

        return ( 
            <>
                <Grid container spacing={1}>
                    <Grid item lg={12} xs={12}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={2} xs={2}>
                                <Box fontWeight={500} color="text.primary">{campo.campo}</Box>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <FormSelect label="Funcionalidad" name={`campos[${campo.idTipoCampo}].funcion`}>
                                    <MenuItem value="columna">Columna</MenuItem>
                                    <MenuItem value="concatenar">Concatenar</MenuItem>
                                </FormSelect>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <FormSelect label="¿Qué hacer al actualizar?" disabled={campo.actualizable === '0'} name={`campos[${campo.idTipoCampo}].comportamientoEnActualizar`}>
                                    <MenuItem value="defecto">Defecto</MenuItem>
                                    <MenuItem value="actualizar">Actualizar</MenuItem>
                                </FormSelect>
                            </Grid>
                            {
                                values.campos[campo.idTipoCampo] && (

                                    values.campos[campo.idTipoCampo].funcion === "columna" && (
                                        <>
                                            <Grid item lg={2} xs={12}>
                                                <FormTextInput fast tooltip="Letra de la columna" type="text" name={`campos[${campo.idTipoCampo}].columna`} label="Columna" />
                                            </Grid>
                                            <Grid item lg={2} xs={12}>
                                                <FormTextInput fast name={`campos[${campo.idTipoCampo}].valorDefecto`} label="Valor por defecto" />
                                            </Grid>
                                        </>
                                    )
                                )
                                
                            }
                            {

                                values.campos[campo.idTipoCampo].funcion === "concatenar" && (
                                    <>
                                            {
                                                values.campos[campo.idTipoCampo].jsonColumnas.map((campoJson, indiceExtra) => (
                                                    <Grid item lg={2} xs={2} key={`camposExtra_${campo.idTipoCampo}_${indiceExtra}`}>
                                                        <Grid container spacing={0} alignItems="center">
                                                            <Grid item lg={10} xs={10}>
                                                                {   <>
                                                                        <Box p={1}>
                                                                            <FormTextInput fast type="text" name={`campos[${campo.idTipoCampo}].jsonColumnas[${indiceExtra}].valor`} label={campoJson.tipo === 'columna' ? 'Columna' : 'Texto'} />
                                                                        </Box>
                                                                    </>
                                                                }
                                                            </Grid>
                                                            <Grid item lg={2} xs={2}>
                                                                
                                                                    <BotonCircular size="small" onClick={() => removeCampoExtra(indiceExtra)} color="secondary" variant="contained" type="button">
                                                                        <CloseIcon fontSize="inherit"/>
                                                                    </BotonCircular>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                    {
                        values.campos[campo.idTipoCampo].funcion === "concatenar" && (
                            <>
                                <Grid item lg={12} xs={12}>
                                    <Box bgcolor={"grey.100"}>
                                        <Grid container spacing={1} justifyContent="center">
                                            <Grid item>
                                                <Button type="button" color="primary" onClick={() => addCampoExtra("columna")}>
                                                    Añadir columna
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button type="button" color="primary" onClick={() => addCampoExtra("texto")}>
                                                    Añadir texto
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </>
                        )
                    }
                    
                </Grid>
            </>
        );

    }
    else 
    {
        return null;
    }
}
 
export default CampoTipoPlantilla;