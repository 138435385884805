import { Box } from '@mui/material';
import BotonCancelar from './BotonCancelar';
import BotonGuardar from './BotonGuardar';

const BotonesFormulario = ({onCancelClick = null, textCancel = "Cancelar", textSave = "Guardar", ...props}) => {
    return ( 
        <>
            <Box mt={"40px"} display="flex" justifyContent="flex-end" sx={{gap: '5px'}} {...props}>
                {
                    typeof onCancelClick === "function" && (
                        <BotonCancelar onClick={onCancelClick}>
                            {textCancel}
                        </BotonCancelar>
                    )
                }
                
                <BotonGuardar>
                    {textSave}
                </BotonGuardar>
            </Box>
        </>
     );
}
 
export default BotonesFormulario;