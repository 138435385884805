import React, { useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import TienePermisos from '../helpers/TienePermisos';
import { ejecutaAPI, prepararDatosSchema } from '../helpers/libFunciones';
import { plantillas as permisosPlantillas } from '../helpers/permisos';
import { schemaPlantillas } from '../pages/home/Plantillas/schemas';
import { useGlobalLoader } from './hooksPropios';

export const PlantillasContext = React.createContext();

const reducer = (state, action) => {

    switch (action.type) {
        case 'CARGANDO_INIT': 
            return {...state, cargando: true};
        case 'CARGANDO_FIN': 
            return {...state, cargando: false};
        case 'SET_DATOS_PLANTILLA':
            return {...state, datosPlantilla: action.payload};
        case 'SET_REDES':
            return {...state, redes: action.payload};
        case 'SET_TIPOS_PLANTILLAS':
            return {...state, tiposPlantillas: action.payload};
        case 'SET_ERROR': 
            return {...state, error: {...action.payload}}
        default:
            throw new Error();
      }
}


export const PlantillasProvider = ({children}) => {

    const [plantillas, dispatch] = useReducer(reducer, {
        datosPlantilla: {},
        tiposPlantillas: [],
        redes: [],
        error: {
            error: false,
            msg: ''
        },
        cargando: true
    });

    return (
        <PlantillasContext.Provider value={[plantillas, dispatch]}>
            {children}
        </PlantillasContext.Provider>
    )
}

export const usePlantillas = (idPlantilla) => {


    const [plantillas, dispatch] = useContext(PlantillasContext);
    
    
    useEffect(() => {

        (async () => {

            const peticionRedes = ejecutaAPI('GET', 'redes?per_page=-1');

            const peticionTipos = ejecutaAPI('GET', 'plantillas/tipos');

            const [listadoRedes, listadoTipos] = await Promise.all([peticionRedes, peticionTipos]);
            
            if (listadoRedes.success && listadoTipos.success)
            {

                dispatch({type: 'SET_REDES', payload: listadoRedes.respuesta.data});

                dispatch({type: 'SET_TIPOS_PLANTILLAS', payload: listadoTipos.respuesta});

                if (!isNaN(idPlantilla) && parseInt(idPlantilla) > 0)
                {
                    //Plantilla existente
                    var {success, respuesta} = await ejecutaAPI('GET', `plantillas/${idPlantilla}`);

                    if (success)
                    {

                        var campos = {};

                        respuesta.campos.forEach(elemento => {

                            campos[elemento.idTipoCampo] = elemento;

                        });

                        respuesta.campos = campos;

                        dispatch({type:'SET_DATOS_PLANTILLA', payload: respuesta});
                    }
                    else 
                    {
                        dispatch({type: 'SET_ERROR', payload: {error: true, msg: 'Se ha producido un error obteniendo los datos'}});
                    }


                }
                else 
                {
                    //Plantilla nueva

                    var datosPlantilla = schemaPlantillas.cast();

                    dispatch({type:'SET_DATOS_PLANTILLA', payload: datosPlantilla});

                }

            }
            else 
            {
                dispatch({type: 'SET_ERROR', payload: {error: true, msg: 'Se ha producido un error obteniendo los datos'}});
            }




            dispatch({type:'CARGANDO_FIN'});


        })();


    }, [idPlantilla, dispatch])

    return {
        ...plantillas,
        schemaPlantillas,
        dispatch

    }
}

export const usePlantillasContext = () => {

    const [plantillas, dispatch] = useContext(PlantillasContext);

    const {setGlobalCargando} = useGlobalLoader();

    const history = useHistory();

    var permisoUsar = permisosPlantillas.crear;

    if (plantillas.datosPlantilla.id !== "new")
    {
        permisoUsar = permisosPlantillas.editar;
    }

    var permisoGuardar = TienePermisos([permisoUsar]);

    const guardarPlantilla = async (values) => {


        setGlobalCargando(true);

        var schema = schemaPlantillas.cast();

        var datosEnviar = prepararDatosSchema(values, schema);

        var campos = {};

        Object.keys(datosEnviar.campos).forEach((elemento, indice) => {

            var datosCampo = datosEnviar.campos[elemento];

            var propsCampoEnviar = {

                id: datosCampo.id,
                columna: datosCampo.columna,
                funcion: datosCampo.funcion,
                idTipoCampo: datosCampo.idTipoCampo,
                valorDefecto: datosCampo.valorDefecto,
                jsonColumnas: datosCampo.jsonColumnas,
                comportamientoEnActualizar: datosCampo.comportamientoEnActualizar


            }

            campos[elemento] = propsCampoEnviar;

        })


        datosEnviar.campos = campos;

        var verbo = "POST";

        var url = "plantillas";

        if (datosEnviar.id)
        {
            verbo = "PUT";

            url = `plantillas/${datosEnviar.id}`;
        }


        const {success, respuesta} = await ejecutaAPI(verbo, url, datosEnviar);

        if (success)
        {

            history.push(`/plantillas`);

        }
        else 
        {
            var msgError = 'Se ha producido un error guardando el envío.';

            if (respuesta !== undefined)
            {
                if (respuesta.data !== undefined)
                {
                    msgError = respuesta.data.msg.join("<br>");
                }
            }


            Swal.fire({
                title: 'Error',
                html: msgError,
                icon: 'error',

            });
        }

        setGlobalCargando(false);

    }

    return {
        ...plantillas,
        schemaPlantillas,
        permisoGuardar,
        guardarPlantilla,
        dispatch

    }

}