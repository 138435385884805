import { EnvioPendienteTransmitirResponse } from "app/hooks/api/envios/useApiGetEnviosPendientesTransmitir";
import { ModificacionParcialEnvioRequest } from "app/hooks/api/envios/useApiModificacionParcialEnvio";
import { TransmitirEnviosRequest } from "app/hooks/api/envios/useApiTransmitirEnvios";
import { cloneDeep } from "lodash";
import { DireccionTrafico, InitialFormTransmitirEnviosValues, ValoresEnvioCambioParcial } from "../models/trafico.types";

export default function transformaDireccionTrafico(direccionTrafico: any): DireccionTrafico {
	return {
		nombre: direccionTrafico.nombre,
		direccion: direccionTrafico.direccion,
		cp: direccionTrafico.cp,
		localidad: direccionTrafico.localidad,
		provincia: direccionTrafico.provincia,
	};
}



export const EnvioParcialMapper = {
	toRequest: (values: ValoresEnvioCambioParcial) => {

        let devolver: ModificacionParcialEnvioRequest = {
            id: values.id
        }

        if (values.peso)
        {
            devolver.peso = values.peso;
        }

		if (values.precio)
		{
			devolver.precio = values.precio;
		}

		return devolver;
	},
};

export function transformaDatosParcialesEnvioParaTrafico(datos: ValoresEnvioCambioParcial, filaTrafico: any) {

	let devolver: any = {};

	if (datos.peso)
	{
		devolver.peso = datos.peso;
	}

	if (datos.precio)
	{
		if (filaTrafico.valoraciones?.length > 0)
		{
			let nuevasValoraciones = [
				{
					...filaTrafico.valoraciones[0],
					precio: datos.precio
				}
			];

			devolver.valoraciones = nuevasValoraciones;
		}
		else 
		{
			//Nos inventamos una valoración temporal
			let valoracion = {
				coste: filaTrafico.importeCostesReales,
				precio: datos.precio,
				id: "-1",
				idEnvio: datos.id,
				referencia: "Paquete",
				servicio: "",
				info: "",
				infoSinCoste: "",
				idRef: "",
				idVariable: null,
				idServicio: null,
				ruta: "",
				adicional: "0",
				tipoIVA: null
			}

			devolver.valoraciones = [valoracion];

		}

	}



	return devolver;
}


export const EnviosPendientesTransmitirMapper = {
	toForm: (datos: EnvioPendienteTransmitirResponse[]) : InitialFormTransmitirEnviosValues => {
		return {
			idCliente: Array.from(new Set(datos.map(dato => dato.idCliente)))
		}
	},
	toRequest: (values: InitialFormTransmitirEnviosValues, envios: any[]) : TransmitirEnviosRequest => {

		let datosEnviados = cloneDeep<InitialFormTransmitirEnviosValues>(values);

		datosEnviados.idCliente = datosEnviados.idCliente.map(dato => dato.toString())

		let idsEnvios = envios.filter(envio => datosEnviados.idCliente.includes(envio.idCliente)).map(envio => envio.id) as string[];
		
		return {
			idEnvio: idsEnvios
		}
	}
}