import { Box, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDelta } from 'react-delta';
import { usePlantillasContext } from '../../../../../../../hooks/usePlantillas';
import CampoTipoPlantilla from './CampoTipoPlantilla/CampoTipoPlantilla';

const CamposPlantilla = () => {

    const {tiposPlantillas} = usePlantillasContext();

    const {values, setFieldValue} = useFormikContext();

    const tipoSeleccionado = tiposPlantillas.find(tipo => tipo.id === values.idTipoPlantilla);

    const tipoSeleccionadoRef = useDelta(tipoSeleccionado);

    useEffect(() => {

        if (tipoSeleccionadoRef && tipoSeleccionado)
        {
            //Setear valores por defecto
            var arrayCampos = {};

            tipoSeleccionado.campos.forEach((campo) => {

                var valoresDefecto = {
                    id: '',
                    idTipoCampo: campo.idTipoCampo,
                    funcion: 'columna',
                    jsonColumnas: [],
                    columna: '',
                    valorDefecto: '',
                    comportamientoEnActualizar: 'defecto'
                }

                //Buscamos si tenemos este campo insertado si tiene ID 

                if (values.id)
                {
                    
                    var campoBuscar = values.campos[campo.idTipoCampo];

                    if (campoBuscar !== undefined)
                    {
                        valoresDefecto = campoBuscar;
                    }
                }

                arrayCampos[campo.idTipoCampo] = valoresDefecto;
                
            });

            setFieldValue('campos', arrayCampos);

        }

    }, [tipoSeleccionado, tipoSeleccionadoRef, values.id, values.campos, setFieldValue])

    if (tipoSeleccionado)
    {

        return ( 
            <>
                <Grid container spacing={1}>

                    <Grid item>
                        <h4>Campos de la plantilla</h4>
                    </Grid>

                    <Grid item lg={12} xs={12}>

                        <Grid container spacing={1}>
                            {
                                tipoSeleccionado.campos.map((campo) => (
                                    <Grid item lg={12} xs={12} key={"campoTipoPlantilla"+campo.idTipoCampo}>
                                        <Box p={1} boxShadow={1}>
                                            <CampoTipoPlantilla campo={campo}/>
                                        </Box>
                                    </Grid>

                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );

    }
    else 
    {
        return null;
    }
}
 
export default CamposPlantilla;